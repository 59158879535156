<template>
  <div id="app-university" class="app-add">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="university" label-width="100px" :rules="rules">
      <el-card style="margin-top:10px">
        <div slot="header" class="clearfix">
          <span>基本信息</span>
        </div>
      <el-form-item label="院校名称" prop="name" label-width="130px">
        <el-input v-model="university.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="院校分类" label-width="130px">
        <el-radio v-for="category in categories" v-model="university.categoryId" :label="category.id">
          {{category.name}}
        </el-radio>
      </el-form-item>
      <el-form-item label="院校属性" label-width="130px">
        <el-checkbox  v-for="type in types" v-model="university.typeIds" :label="type.id">
          {{type.name}}
        </el-checkbox >
      </el-form-item>
      <el-form-item label="院校地区" label-width="130px">
        <el-radio  v-for="area in areas" v-model="university.areaId" :label="area.id">
          {{area.name}}
        </el-radio >
      </el-form-item>
      <el-form-item label="推免人数" label-width="130px" prop="recommendNum">
        <el-input type="number" v-model="university.recommendNum" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否博士点" label-width="130px">
        <el-radio v-model="university.isDoctoralProgram" :label="false">否</el-radio>
        <el-radio v-model="university.isDoctoralProgram" :label="true">是</el-radio>
      </el-form-item>
        <el-form-item label="专业类型" label-width="130px" >
          <el-checkbox  v-for="major in majors" v-model="university.majorIds" :label="major.id">
            {{major.name}}
          </el-checkbox >
        </el-form-item>
        <el-form-item label="硕士类型" label-width="130px" >
          <el-checkbox  v-for="master in masters" v-model="university.masterIds" :label="master.id">
            {{master.name}}
          </el-checkbox >
        </el-form-item>
        <el-form-item label="学习方式" label-width="130px" >
          <el-checkbox  v-for="learnWay in learnWays" v-model="university.learnWayIds" :label="learnWay.id">
            {{learnWay.name}}
          </el-checkbox >
        </el-form-item>
        <el-form-item label="加分及照顾政策" label-width="130px" >
          <el-checkbox  v-for="policy in policies" v-model="university.policyIds" :label="policy.id">
            {{policy.name}}
          </el-checkbox >
        </el-form-item>
      <el-form-item label="排序" label-width="130px">
        <el-input type="number" v-model="university.sort" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item style="display: none" label-width="130px">
        <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
      </el-card>
      <el-card class="box-card" style="margin-top:10px">
        <div slot="header" class="clearfix">
          <span>上届复试分数</span>
        </div>
        <el-form-item label="院校线-法学" label-width="130px" prop="retestScoreStandardLaw">
          <el-input type="number" v-model="university.retestScoreStandardLaw" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="院校线-非法学" label-width="130px" prop="retestScoreStandardNonlaw">
          <el-input type="number" v-model="university.retestScoreStandardNonlaw" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="最低录取分" label-width="130px" prop="retestScoreMinimum">
          <el-input type="number" v-model="university.retestScoreMinimum" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="单科-政治" label-width="130px" prop="retestScoreZhengzhi">
          <el-input type="number" v-model="university.retestScoreZhengzhi" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="单科-外语" label-width="130px" prop="retestScoreWaiyu">
          <el-input type="number" v-model="university.retestScoreWaiyu" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="单科-法基" label-width="130px" prop="retestScoreFaji">
          <el-input type="number" v-model="university.retestScoreFaji" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="单科-法综" label-width="130px" prop="retestScoreFazong">
          <el-input type="number" v-model="university.retestScoreFazong" placeholder="请输入"></el-input>
        </el-form-item>
      </el-card>
      <el-card class="box-card" style="margin-top:10px">
        <div slot="header" class="clearfix">
          <span>上届招生人数</span>
        </div>
        <el-form-item label="全日制-法学" label-width="130px" prop="fulltimeLawStudentNum">
          <el-input type="number" v-model="university.fulltimeLawStudentNum" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="全日制-非法学" label-width="130px" prop="fulltimeNonlawStudentNum">
          <el-input type="number" v-model="university.fulltimeNonlawStudentNum" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="非全日制-法学" label-width="130px" prop="parttimeLawStudentNum">
          <el-input type="number" v-model="university.parttimeLawStudentNum" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="非全日制-非法学" label-width="130px" prop="parttimeNonlawStudentNum">
          <el-input type="number" v-model="university.parttimeNonlawStudentNum" placeholder="请输入"></el-input>
        </el-form-item>
      </el-card>
      <el-card class="box-card" style="margin-top:10px">
        <div slot="header" class="clearfix">
          <span>复试信息</span>
        </div>
        <el-form-item label="初复试分值比重" label-width="130px">
          <el-input type="text" v-model="university.scoreWeight" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="报录比" label-width="130px">
          <el-input type="text" v-model="university.admissionRate" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="复试形式" label-width="130px">
          <el-input type="text" v-model="university.retestForm" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="复试内容" label-width="130px">
          <el-input type="text" v-model="university.retestContent" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="学费" label-width="130px">
          <el-input type="text" v-model="university.tuition" placeholder="请输入"></el-input>
        </el-form-item>
      </el-card>
      <el-card class="box-card" style="margin-top:10px">
        <div slot="header" class="clearfix">
          <span>院校信息</span>
        </div>
        <el-form-item label="复试分数线" label-width="130px">
          <quill-editor @focus="quill = this" class="editor" v-model="university.detailRetestScore" :options="quillOption"></quill-editor>
        </el-form-item>
        <el-form-item label="招生规模" label-width="130px">
          <quill-editor @focus="quill = this" class="editor"  v-model="university.detailStudentNum"  :options="quillOption"></quill-editor>
        </el-form-item>
        <el-form-item label="初试报考" label-width="130px">
          <quill-editor @focus="quill = this" class="editor"  v-model="university.detailFirstTest" :options="quillOption"></quill-editor>
        </el-form-item>
      </el-card>
      <el-card class="box-card" style="margin-top:10px">
        <div slot="header" class="clearfix">
          <span>复试录取分析</span>
        </div>
        <el-form-item label="复试要求" label-width="130px">
          <quill-editor @focus="quill = this" class="editor"  v-model="university.detailRetestCondition" :options="quillOption"></quill-editor>
        </el-form-item>
        <el-form-item label="录取分析" label-width="130px">
          <quill-editor @focus="quill = this" class="editor"  v-model="university.detailAdmissionAnalysis"  :options="quillOption"></quill-editor>
        </el-form-item>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import { UrlEnum,TipEnum } from "../../../../public/js/common-vue";
import { request } from "../../../../public/js/common-vue";
import { quillEditor } from 'vue-quill-editor'
import quillConfig from "@/assets/js/quill-config.js";
import Loading from "@/components/Loading";

export default {
  components: {Loading},
  data() {
    return {
      loading: false,
      university: {},
      categories:[],
      majors:[],
      types:[],
      masters:[],
      learnWays:[],
      policies:[],
      areas:[],
      quillOption: quillConfig,
      rules:{
        name :[{required: true, message:'请输入院校名称', trigger:'blur'}],
        recommendNum:[{required: true, message:'请输入推免人数', trigger:'blur'}],
        fulltimeLawStudentNum :[{required: true, message:'请输入招生人数', trigger:'blur'}],
        fulltimeNonlawStudentNum :[{required: true, message:'请输入招生人数', trigger:'blur'}],
        parttimeLawStudentNum :[{required: true, message:'请输入招生人数', trigger:'blur'}],
        parttimeNonlawStudentNum :[{required: true, message:'请输入招生人数', trigger:'blur'}],
        retestScoreStandardLaw:[{required: true, message:'请输入复试分数', trigger:'blur'}],
        retestScoreStandardNonlaw:[{required:true,message:'请输入复试分数',trigger:'blur'}],
        retestScoreMinimum:[{required: true, message:'请输入复试分数', trigger:'blur'}],
        retestScoreZhengzhi:[{required: true, message:'请输入复试分数', trigger:'blur'}],
        retestScoreWaiyu:[{required: true, message:'请输入复试分数', trigger:'blur'}],
        retestScoreFaji:[{required: true, message:'请输入复试分数', trigger:'blur'}],
        retestScoreFazong:[{required: true, message:'请输入复试分数', trigger:'blur'}]
      },
    }
  },
  methods: {
    getCategories:function(){
      this.$http({
        method: "get",
        url: UrlEnum.UNIVERSITY_CATEGORIES+'?page=1&size=100',
      }).then((res) => {
        this.categories = res.data.list;
        this.university.categoryId = parseInt(this.university.categoryId);
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getTypes:function(){
      this.$http({
        method: "get",
        url: UrlEnum.UNIVERSITY_TYPES+'?page=1&size=100',
      }).then((res) => {
        this.types = res.data.list;
        this.university.typeIds = this.university.typeIds;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getMajors:function(){
      this.$http({
        method: "get",
        url: UrlEnum.MAJORS+'?page=1&size=100',
      }).then((res) => {
        this.majors = res.data.list;
        this.university.majorId = localStorage.getItem(LOCALSTORAGE_UNIVERSITY_MAJOR_KEY);
        if(this.university.majorId == null || this.university.majorId == 'undefined'){
          this.university.majorId = res.data.list[0].id;
        }
        this.university.majorId = parseInt(this.university.majorId);
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getAreas:function(){
      this.$http({
        method: "get",
        url: UrlEnum.UNIVERSITY_AREAS+'?level=2&page=1&size=100',
      }).then((res) => {
        this.areas = res.data.list;
        this.university.areaId = localStorage.getItem(LOCALSTORAGE_UNIVERSITY_AREA_KEY);
        if(this.university.areaId == null || this.university.areaId == 'undefined'){
          this.university.areaId = res.data.list[0].id;
        }
        this.university.areaId = parseInt(this.university.areaId);
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getMasters:function(){
      this.$http({
        method: "get",
        url: UrlEnum.MASTERS+'?page=1&size=100',
      }).then((res) => {
        this.masters = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getLearnWays:function(){
      this.$http({
        method: "get",
        url: UrlEnum.LEARN_WAYS+'?page=1&size=100',
      }).then((res) => {
        this.learnWays = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getPolicies:function(){
      this.$http({
        method: "get",
        url: UrlEnum.UNIVERSITY_POLICIES+'?page=1&size=100',
      }).then((res) => {
        this.policies = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData: function () {
      this.$http({
        method: "get",
        url: UrlEnum.UNIVERSITIES + "/" + request('id'),
      }).then((res) => {
        this.university = res.data;
        console.log(JSON.stringify(this.university));
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    submit: function(formData){
      this.$refs[formData].validate((valid) => {if (valid) {
        this.update();
      }});
    },
    update:function (){
      this.loading=true;
      this.$http({
        method: "put",
        url: UrlEnum.UNIVERSITIES + "/" + request('id'),
        data: this.university,
      })
          .then((res) => {
            this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
            const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
            setTimeout(function () {
              parent.layui.table.reload('tableData'); //重载表格
              parent.layer.close(index); //再执行关闭
            }, 800);
          })
          .catch((res) => {
            this.loading=false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    }
  },
  mounted() {
    this.initData();
    this.getCategories();
    this.getTypes();
    this.getAreas();
    this.getMajors();
    this.getMasters();
    this.getLearnWays();
    this.getPolicies();
  }
}
</script>

<style>
@import "../../../../static/css/add.css";
@import "../../../../static/css/editor.css";
@import "../../../../static/css/teach/university/add.css";
</style>
